<template>
  <!--
  this dialog allow us to select tables for specific reservation using table planer
  -->
  <v-dialog v-model="visible" fullscreen persistent scrollable>
    <v-card>
      <v-card-title class="pa-0">
        <v-app-bar :color="this.$vuetify.theme.dark? 'dark-bg' : 'primary'">
          <v-app-bar-title class="white--text">
            {{ $t('generic.lang_chooseTables') }}
          </v-app-bar-title>
          <v-spacer/>

          <!--dialog actions-->
          <v-btn :color="this.$vuetify.theme.dark? 'dark-bg' : 'white'" :elevation="this.$vuetify.theme.dark? '4' : '0'"
                 @click="close">
            {{ this.$t('generic.lang_save') }}
          </v-btn>
          <v-app-bar-nav-icon @click="close">
            <v-icon x-large color="error">close</v-icon>
          </v-app-bar-nav-icon>

          <template class="pa-0" v-slot:extension>
            <v-progress-linear class="ma-0" v-if="rooms_loader" color="white" height="30" indeterminate>
              {{ $t('generic.loading_availableTables') }} ...
            </v-progress-linear>
            <v-tabs v-else v-model="room" color="white">
              <v-tab :key="i" v-for="(room , i) in rooms">
                {{ room.room_name }}
              </v-tab>
            </v-tabs>
          </template>
        </v-app-bar>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-tabs-items v-model="room">
          <v-tab-item :key="i" v-for="(room , i) in rooms">
            <div id="cont" ref="cont" class="bg-default cont responsiveTable"
                 :style="{height:($vuetify.breakpoint.height-112)+'px !important'}">
              <div id="floor" ref="floor" :style="{backgroundColor:room.background}" class="floor">
                <vue-drag-resize v-for="(item) in room.tables"
                                 :key="item.name"
                                 :class="{drag:item.active}"
                                 :h="item.height" :isActive="false"
                                 :isDraggable="false" :isResizable="false" :minh="50"
                                 :minw="50" :preventActiveBehavior="true"
                                 :parentLimitation="true" :w="item.width" :x="item.left"
                                 :y="item.top"
                                 :aria-multiselectable="false"
                >
                  <div :class="['room-table',item.type,tables.includes(item.uuid)?'selectedTable':'']"
                       :style="{backgroundColor:item.color}" @click="selectTable(item.uuid)">
                    <v-row align="center" class="w-100 h-50" justify="space-between" no-gutters>
                      <v-col align="center" align-self="start" cols="12">
                        <font-awesome-icon v-if="tables.includes(item.uuid)" class="success--text" size="2x"
                                           :icon="['fad','check-circle']"/>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="w-100 h-50" justify="space-between" no-gutters>
                      <v-col align="center" align-self="center" cols="12">
                        <span class="table-name">{{ item.name }}</span>
                      </v-col>
                    </v-row>
                    <v-row align="end" class="w-100 h-50" justify="center" no-gutters>
                      <span class="table-client px-1">{{ item.maxParties }}</span>
                    </v-row>
                  </div>
                </vue-drag-resize>

              </div>
            </div>

          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/*import components*/
import VueDragResize from 'vue-drag-resize'
/*import plugins*/
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheckCircle} from '@fortawesome/pro-duotone-svg-icons'

library.add(faCheckCircle)

export default {
  name: "ReservationTableSelector",
  components: {VueDragResize, 'font-awesome-icon': FontAwesomeIcon},
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    /*uuid of selected reservation type*/
    reservation_type: {
      type: String,
      required: true
    },
    /*selected date*/
    date: {
      type: String,
      required: true
    },
    /*selected time*/
    time: {
      type: String,
      required: true
    },
    /*selected number of persons*/
    persons: {
      type: Number,
      required: true
    },
    end_at: {
      type: String,
    }
  },
  data: () => ({
    rooms_loader: false,
    rooms: [],
    room: 0,//for rooms tabs
    tables: [],
  }),
  computed: {
    /*dialog accessors*/
    visible: {
      get() {
        return this.dialog
      },
      set(val) {

        /*send data to the  parent component when we close the dialog*/
        if (!val) {
          this.$emit('closed', this.tables)
        }
        this.$emit("selected", this.rooms.map(
                (room) => room.tables
            ).flat(1).filter(
                (table) => this.tables.includes(table.uuid)
            ).map(
                (table) => table.name
            )
        );

        this.$emit('input', val)
      }
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        /*re-load rooms + tables after opening the dialog*/
        this.loadRooms();

        /*select first room if exists*/
        this.room = 0;
      }
    }
  },
  methods: {
    /*close dialog and emit tables*/
    close() {
      this.visible = false;
    },
    /*load rooms with tables*/
    selectTable(uuid) {
      if (this.tables.includes(uuid)) {
        let newTables = []
        this.tables.forEach(tableUUID => {
          if (tableUUID !== uuid) {
            newTables.push(tableUUID)
          }
        });
        this.tables = newTables;
      } else {
        this.tables.push(uuid);
      }
    },
    loadRooms() {
      if (parseInt(this.persons) > 0 && this.date !== undefined && this.time !== undefined && (this.reservation_type !== undefined && this.reservation_type !== '')) {
        this.rooms_loader = true;
        let self = this;
        this.rooms = [];
        this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.TABLES, {
          person: this.persons,
          resType: this.reservation_type,
          date: this.date,
          time: this.time,
          end_at: this.end_at
        }).then((res) => {
              if (res.data.STATUS === "SUCCESS") {

                this.rooms = res.data.rooms.filter((elt) => elt.tables.length > 0);
                let parentWidth = this.$vuetify.breakpoint.width, parentHeight = this.$vuetify.breakpoint.height - 112;

                this.rooms.forEach((room) => {

                  for (let i = 0; i < room.tables.length; i++) {
                    room.tables[i].left = (parseFloat(room.tables[i].left / 100) * parseFloat(parentWidth));
                    room.tables[i].top = (parseFloat(room.tables[i].top / 100) * parseFloat(parentHeight));
                    room.tables[i].width = (parseFloat(room.tables[i].width / 100) * parseFloat(parentWidth));
                    room.tables[i].height = (parseFloat(room.tables[i].height / 100) * parseFloat(parentHeight));
                  }
                })
              } else if (res.data.STATUS === "FAILED") {

                Events.$emit("showSnackbar", {
                  message: res.data.msg,
                  color: "error",
                });

                this.close();
              } else {

                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: "error"
                });
              }
            }
        ).catch((err) => {

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
            color: "error"
          });
        }).finally(() => {
          this.rooms_loader = false;
        })
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 900px) {
  .responsiveTable {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    position: relative;
    top: 100px;
    width: 600px !important;
    height: 400px !important;
    left: -100px;
  }
}

.cont {
  width: 100%;
  height: 768px;
}

.room-table {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.rect {
  border-radius: 10px;
}

.round {
  border-radius: 600px;
}

.floor {
  position: absolute;
  width: 100%;
  height: 100%;
}

.room-table-filter {
  display: block;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px 0px 3px 3px;
}

.table-name {
  overflow: hidden;
  font-size: 16px;
  color: white;
  user-select: none;
}

.table-client {
  text-align: center;
  height: 20px;
  min-width: 20px;
  font-size: 14px;
  border-radius: 50%;
  margin-bottom: 5px;
  background: black;
  color: white;
  opacity: 0.2;
  z-index: 0;
}

.table-total {
  margin-top: 5px;
  text-align: center;
  height: 20px;
  border-radius: 600px;
  min-width: 40px;
  background: black;
  color: white;
  opacity: 0.2;
  z-index: 0;
  font-size: 14px;
  user-select: none;
}

.table-state {
  text-align: center;
  height: 20px;
  width: 30px;
  z-index: 0;
  font-size: 20px;
  color: white;
  user-select: none;
}

.table-timer {
  text-align: center;
  height: 20px;
  width: 30px;
  z-index: 0;
  font-size: 20px;
  color: white;
  user-select: none;
}

.editor-btn {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  font-size: 20px;
  margin: 8px;
  line-height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: lightgrey;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.editor-option {
  position: absolute;
  top: 0;
  left: 40px;
  margin: 8px;
  line-height: 34px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.editor-option btn {
  position: relative;
  width: 32px;
  display: inline-block;
  cursor: pointer;
  margin-right: -4px;
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  transition: all 150ms linear;
}

.activated {
  background: #444;
  border-color: transparent;
  color: white;
}

.color-palette {
  position: absolute;
}

.swatch-holder-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.swatch-holder {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 5px 5px 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .7;
  display: inline-block;
  border-radius: 30px;
}

.bg-default {
  background-color: #098650;
}

.selectedTable {
  border-color: #4caf50;
  border-width: 2px;
  border-style: solid;
  filter: brightness(1.2);
}

</style>
