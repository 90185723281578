<template>
  <v-dialog
    scrollable
    max-width="800"
    v-model="visible"
  >
    <v-card>
      <v-card-title class="card-header">
        {{ this.$t('generic.lang_edit_order_details') }}
      </v-card-title>
      <v-card-text class="py-4">
        <v-form
          lazy-validation
          ref="form"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :rules="[
                  (v) =>
                    !!v && RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(v),
                ]"
                v-model="form.customerEmail"
                :label="this.$t('generic.lang_customer_email')"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :rules="[
                  (v) =>
                    !!v &&
                    RegExp(
                      /^(?:\+\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,50}$|^(?:\+\d{1,3}\s?)?\d{11,50}$/
                    ).test(v),
                ]"
                v-model="form.phoneNumber"
                :label="this.$t('generic.lang_customer_phone')"
                dense
                outlined
              />
            </v-col><v-col
              cols="12"
            >
              <v-textarea
                v-model="form.note"
                :label="this.$t('generic.lang_customerNote')"
                dense
                rows="4"
                outlined
              />
            </v-col>

            <!--   <v-col cols="12">
              <v-select
                :rules="[(v) => !!v]"
                @change="changeTicketUuid($event)"
                :label="this.$t('eventbee.lang_ticket')"
                v-model="form.date"
                dense
                outlined
                item-value="calendarFrom"
                :items="this.tickets"
              >
                <template v-slot:item="{ item }">
                  <strong>
                    {{ item.calendarFrom }}
                  </strong>
                  <span class="pl-3">
                    ({{ item.timefrom.substr(0, 5) }} -
                    {{ item.timeto.substr(0, 5) }})
                  </span>
                </template>

                <template v-slot:selection="{ item }">
                  <strong>
                    {{ item.calendarFrom }}
                  </strong>
                  <span class="pl-3">
                    ({{ item.timefrom.substr(0, 5) }} -
                    {{ item.timeto.substr(0, 5) }})
                  </span>
                </template>
              </v-select>
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="card-footer py-0">
        <v-btn
          :disabled="this.loading"
          @click="visible = false"
          color="error"
          text
        >
          {{ this.$t('generic.lang_cancel') }}
        </v-btn>

        <v-spacer />
        <v-btn
          :loading="this.loading"
          :disabled="this.loading"
          @click="updateOrder"
          color="primary"
          depressed
        >
          {{ this.$t('generic.lang_save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';

export default {
  name: 'EditEventBeeOrderDialog',
  props: {
    value: Boolean,
    orderUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tickets: [],
      loading: false,
      form: {
        phoneNumber: '',
        customerEmail: '',
        date: '',
        ticketUUID: '',
      },
      event: null,
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    orderUuid: {
      handler(val) {
        this.getOrderDetails();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeTicketUuid($event) {
      let search = this.tickets.find(
        (ticket) => ticket.calendarFrom === $event
      );

      if (search) this.form.ticketUUID = search.UUID;
    },
    getTickets(event_id) {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.EVENT.TICKETS, {
          eventUUID: event_id,
        })
        .then((res) => {
          if (res.data.status) {
            this.tickets = [...res.data.data].map((ticket) => {
              ticket.ticket_uuid = ticket.UUID;
              return ticket;
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getOrderDetails() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.ORDER_LIST.ORDER_DETAILS, {
          uuid: this.orderUuid,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            this.event = { ...res.data.data };
            this.getTickets(res.data.data?.eventUUID);
            //fill form data
            this.form.uuid = this.event.uuid;
            this.form.customerEmail = this.event?.customerData?.email;
            this.form.phoneNumber = this.event?.customerData?.telPrivate;
            this.form.ticketUUID = this.event?.ticket?.ticketUUID;
            this.form.date = this.event.eventDate;
            this.form.note = this.event.note;
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateOrder() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.ORDER_LIST.UPDATE, this.form)
        .then((res) => {
          if (res.data.STATUS) {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_actionSuccessful'),
              color: 'success',
            });

            this.$emit('orderUpdated');
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
