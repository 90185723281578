<template>
  <div>
    <page-title :heading="'EventBee'" :icon=icon :subheading="$t('generic.lang_orders')"
    ></page-title>
    <div class="app-main__inner">
      <EventBeeOrdersComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import EventBeeOrdersComponent from "../../components/eventbee/EventBeeOrdersComponents";
export default {
  name: "EventOrders",
  components: {EventBeeOrdersComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-ticket icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>