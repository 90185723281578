<template>

  <div class="content mt-0 pa-0">
    <!--reservation types -->
    <div v-if="currentTab === 'tab1'">

      <v-card class="main-card mb-3 card pa-0 ma-0 border-top" outlined>

        <div class="card-body pa-0">
          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-title  card-header">
            {{ $t('generic.lang_bookings') }}
          </div>
          <b-tabs v-model="tab" content-class="mt-3">
            <b-tab :active="this.tab===0" @click="changeTab(0)">
              <template v-slot:title>
                <font-awesome-icon :icon="['fal', 'list']" class="text-muted actions" size="2x"
                                   style="font-size: 16px"/>
                <span class="pl-2">{{ $t('generic.lang_dailyList') }}</span>
              </template>
              <EventBeeDailyOrdersComponent/>
            </b-tab>
            <b-tab :active="this.tab===1" :title="$t('generic.lang_calendar')" @click="changeTab(1)">
              <template v-slot:title>
                <font-awesome-icon :icon="['fal', 'calendar']" class="text-muted actions" size="2x"
                                   style="font-size: 16px"/>
                <span class="pl-2">{{ $t('eventbee.lang_currentCheckedIn') }}</span>
              </template>
              <EventBeeCurrentChekedinComponent/>
            </b-tab>
          </b-tabs>
        </div>

      </v-card>

    </div>

  </div>

</template>

<script>
import Tabs from 'vue-tabs-with-active-line';
import Calendar from "@/views/accounting/Calendar";

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faCalendarCheck,
  faCalendarTimes,
  faGlobe,
  faList,
  faPhone,
  faPowerOff,
  faStream,
  faUserCheck,
  faUsers,
  faUserTimes
} from '@fortawesome/pro-light-svg-icons';
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

import countTo from 'vue-count-to';
import EventBeeDailyOrdersComponent from "./EventBeeDailyOrdersComponent";
import EventBeeCurrentChekedinComponent from "./EventBeeCurrentChekedinComponent";

library.add(faCalendar, faList, faUserCheck, faUserTimes, faUsers, faGlobe, faPhone, faPowerOff, faCalendarTimes, faCalendarCheck, faStream);

export default {
  components: {
    EventBeeDailyOrdersComponent,
    EventBeeCurrentChekedinComponent,
    countTo,
    Calendar,
    'font-awesome-icon': FontAwesomeIcon,
    Tabs
  },
  name: "EventBeeOrdersComponent",
  data: () => {
    return {
      loading: false,
      showCreateEvent:false,
      reservation_enabled: false,
      currentTab: 'tab1',
      loadData: false,
      tab: 0,
      statistics: {
        canceled: 0,
        confirmed: 0,
        online: 0,
        phone: 0,
        unconfirmed: 0,
        customers: 0
      }
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    refresh(){
      if(this.tab===0){
        this.$refs.eventsList.getDataFromApi();
      }
    }
  }
}
</script>

<style>
.shadow-tabs {
  z-index: 1 !important;
}
</style>
