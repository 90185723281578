var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"800"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',{staticClass:"card-header"},[_vm._v(" "+_vm._s(this.$t('generic.lang_edit_order_details'))+" ")]),_c('v-card-text',{staticClass:"py-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
                (v) =>
                  !!v && RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(v),
              ],"label":this.$t('generic.lang_customer_email'),"dense":"","outlined":""},model:{value:(_vm.form.customerEmail),callback:function ($$v) {_vm.$set(_vm.form, "customerEmail", $$v)},expression:"form.customerEmail"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
                (v) =>
                  !!v &&
                  RegExp(
                    /^(?:\+\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,50}$|^(?:\+\d{1,3}\s?)?\d{11,50}$/
                  ).test(v),
              ],"label":this.$t('generic.lang_customer_phone'),"dense":"","outlined":""},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":this.$t('generic.lang_customerNote'),"dense":"","rows":"4","outlined":""},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"card-footer py-0"},[_c('v-btn',{attrs:{"disabled":this.loading,"color":"error","text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" "+_vm._s(this.$t('generic.lang_cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"loading":this.loading,"disabled":this.loading,"color":"primary","depressed":""},on:{"click":_vm.updateOrder}},[_vm._v(" "+_vm._s(this.$t('generic.lang_save'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }